<template>
  <v-container v-if="options">
    <highcharts :options="options"/>
    <VDivider class="my-5"/>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'ChartView',

  components: {
    highcharts: Chart,
  },

  props: ['options'],
};

</script>
