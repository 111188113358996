import dayjs from 'dayjs';

function continueBasedOnType(type, oldDate, newDate) {
    // If we had an error, always retry!
    if (this.hasError === true) {
        return true;
    }

    // Different year = always reload
    if (dayjs(oldDate).year() !== dayjs(newDate).year()) {
        return true;
    }

    // If the type is year, no need to refresh
    if (type === 'year') {
        return false;
    }

    // Different month at this point is reload
    if (dayjs(oldDate).month() !== dayjs(newDate).month()) {
        return true;
    }

    // If the type is month, no need to refresh
    if (type === 'month') {
        return false;
    }

    // Different week at this point is reload
    if (dayjs(oldDate).week() !== dayjs(newDate).week()) {
        return true;
    }

    // If the type is week, no need to refresh
    if (type === 'week') {
        return false;
    }

    // Whether the type is day or something else, just refresh
    return true;
}

export default {
    continueBasedOnType: continueBasedOnType
};
