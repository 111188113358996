<template>
  <div class="text-center">
    <v-icon>mdi-alert</v-icon>
    <v-card-text>Er ging iets mis, selecteer een andere datum of probeer het later opnieuw.</v-card-text>
  </div>
</template>

<script>
export default {
  name: 'ChartErrorView',
};
</script>