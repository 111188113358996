<template>
  <v-container>
    <ChartLoadingView v-if="isLoading"/>
    <ChartErrorView v-if="hasError"/>

    <v-container v-if="!isLoading && !hasError">
      <h3 v-if="total > 0" class="text-h4 text-center">Totaal: {{ total }} stuks.</h3>
      <VDivider v-if="total > 0" class="divider"/>

      <GraphView v-for="graph in graphs"
                 v-bind:key="graph.index"
                 :options="graph"/>

    </v-container>

  </v-container>

</template>

<script>
import ChartErrorView from '@/components/ChartErrorView.vue';
import ChartLoadingView from '@/components/ChartLoadingView.vue';
import GraphView from '@/components/GraphView.vue';
import chartRefreshHelper from '@/helpers/chartRefreshHelper';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

export default {
  name: 'ChartView',
  components: {
    ChartErrorView,
    ChartLoadingView,
    GraphView,
  },

  props: ['value', 'type', 'method'],

  watch: {
    '$route.params'() {
      this.updateData(this.oldDate, true);
    },
    value: {
      immediate: true,
      handler: function (date) {
        this.updateData(date, false);
      },
    },
  },

  methods: {
    updateData: function (date, force) {
      if (!force && !chartRefreshHelper.continueBasedOnType(this.type, this.oldDate, date)) {
        return;
      }

      this.isLoading = true;
      this.hasError = false;

      if (this.method === null) {
        this.isLoading = false;
        this.hasError = true;
        return;
      }

      this.method(this.type, date)
          .then(info => {
            this.isLoading = false;

            if (info.status !== 200 || info.data === null) {
              this.hasError = true;
              return;
            }

            this.total = info.data.totalSold;

            for (let i = 0; i < info.data.graphs.length; i++) {
              info.data.graphs[i].index = i;
              if (info.data.graphs[i].isCountryClickable === true) {
                info.data.graphs[i].plotOptions = {
                  series: {
                    cursor: 'pointer',
                    events: {
                      click: this.onClickCountry,
                    },
                  },
                };
              }
            }

            this.graphs = info.data.graphs;

          })
          .catch(() => {
            this.isLoading = false;
            this.hasError = true;
          });

      this.oldDate = date;
    },
    onClickCountry: function (event) {
      try {
        const countryName = event.point.series.options.name;
        const countryId = event.point.series.options.options.countryId;
        const target = this.$route.params.product ? 'SaleProduct' : 'SaleCountry';
        this.$router.push({
          name: target,
          params:
              {
                country: countryName,
                countryId: countryId,
                product: this.$route.params.product ?? '',
                productId: this.$route.params.productId ?? -1,
              },
        });
      } catch {
        // Probably not the correct chart
      }
    },
  },

  data() {
    return {
      isLoading: true,
      hasError: false,
      oldDate: String,
      total: 0,
      graphs: [],
    };
  },

  mounted: function () {
    dayjs.extend(weekOfYear);
  },
}
;
</script>
