import { post } from '../implementation/app';

function getStatistics(type, date, product, country, customer) {
  let data = {
    'type': type,
    'date': date,
  };

  if (product !== 'undefined' && product !== null && product > 0) {
    data.product = product;
  }

  if (country !== 'undefined' && country !== null && country > 0) {
    data.country = country;
  }

  if (customer !== 'undefined' && customer !== null && customer > 0) {
    data.customer = customer;
  }

  return post(`statistics`, data);
}

export { getStatistics };
