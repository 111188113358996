export default [
    {
        title: 'Jaar overzicht',
        type: 'year',
    },
    {
        title: 'Maand overzicht',
        type: 'month',
    },
    {
        title: 'Week overzicht',
        type: 'week',
    },
    {
        title: 'Dag overzicht',
        type: 'day',
    },
];

