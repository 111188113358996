<template>
  <div class="text-center">
    <v-progress-circular color="accent" indeterminate></v-progress-circular>
    <v-card-text> De gevraagde inhoud wordt ingeladen.</v-card-text>
  </div>
</template>

<script>
export default {
  name: 'ChartLoadingView',
};
</script>